.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: center;
  width: 100%;
}
.title {

}
.search {
  flex-grow: 1;
  max-width: 785px;
}

.action {
  flex-grow: 1;
  display: flex;
  gap: 0.5rem;
  a {
    max-width: 350px;
    white-space: nowrap
  }

  &_create {
    display: flex !important;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
  }

  &_propositions {
    display: flex !important;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
  }

}