@mixin for-size($size) {
    @if $size == mobile {
        @media (max-width: 767px) { @content; }
    } @else if $size == tablet {
        @media (min-width: 768px) and (max-width: 1199px) { @content; }
    } @else if $size == desktop {
        @media (min-width: 1200px) { @content; }
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.pricing-block {
    &__panel {
        &_active {
            opacity: 1;
            animation-name: fadeInOpacity;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 0.5s;
        }
    }

    &__card-block {
        position: relative;
        display: block;
        width: 100%;
        max-width: 320px;
        border-radius: 10px;
        padding-bottom: 25px;
        border: 2px solid #fff;
        background-color: #fff;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
        transition: all 0.5s ease-in-out;
        margin-bottom: 24px;
        &:not(:nth-child(3n)) {
            margin-right: 45px;
        }
    }
    &__card-overhead {
        position: relative;
        display: block;
        text-align: center;
        width: calc(100% + 4px);
        margin-top: -2px;
        margin-left: -2px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        color: #fff;
        border-radius: 10px 10px 0 0;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-bottom: 22px;
    }
    &__card-block.small &__card-overhead {
        background-color: #34A853;
    }
    &__card-block.medium &__card-overhead {
        background-color: #4285F4;
    }
    &__card-block.large &__card-overhead {
        background-color: #EA4335;
    }
    &__card-overhead-icon {
        position: absolute;
        left: 50%;
        margin-left: -9px;
        bottom: -8px;
        display: block;
        fill: #34A853;
        transition: all 0.5s ease-in-out;
    }
    &__card-block.medium &__card-overhead-icon {
        fill: #4285F4;
    }
    &__card-block.large &__card-overhead-icon {
        fill: #EA4335;
    }
    &__card-block.medium .tariff-btn--select svg {
        stroke: #4285F4;
    }
    &__card-block.large .tariff-btn--select svg {
        stroke: #EA4335;
    }
    &__card-price {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        font-size: 65px;
        font-weight: 500;
        color: #354052;
        letter-spacing: -0.03em;
        line-height: 1.1;
        span.currency {
            display: block;
            font-size: 25px;
            margin-right: 6px;
        }
    }
    &__card-billing {
        display: block;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 1.7;
        margin-bottom: 20px;
    }
    &__card-free-block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 280px;
        height: 40px;
        margin: 0 auto;
        border-radius: 8px;
        line-height: 1.46;
        font-size: 15px;
        font-weight: 500;
        color: #354052;
        border: 2px solid #354052;
        margin-bottom: 16px;
        transition: all 0.5s ease-in-out;
    }
    &__card-block.small:hover &__card-free-block {
        background-color: #34A853;
        border-color: #34A853;
        color: #fff;
        text-decoration: none;
    }
    &__card-block.medium:hover &__card-free-block {
        background-color: #4285F4;
        border-color: #4285F4;
        color: #fff;
        text-decoration: none;
    }
    &__card-block.large:hover &__card-free-block {
        background-color: #EA4335;
        border-color: #EA4335;
        color: #fff;
        text-decoration: none;
    }
    &__card-bonus {
        display: block;
        text-align: center;
        width: 100%;
        max-width: 180px;
        margin: 0 auto;
        color: #34A853;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.4;
    }
    &__card-wrapper {
        position: relative;
        display: block;
        padding-left: 35px;
        padding-right: 35px;
        padding-bottom: 16px;
        margin-bottom: 16px;
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            margin-left: -140px;
            bottom: 0;
            width: calc(100% - 70px);
            height: 1px;
            background-color: #EBF0FB;
        }
    }
    &__card-list {
        display: block;
    }
    &__card-list ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        padding-left: 19px;
    }
    &__card-list ul li {
        position: relative;
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #354052;
        line-height: 1.2;
        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: -19px;
            width: 9px;
            height: 8px;
            background-image: url('../../../../../../../assets/images/custom/tariff-card-checked.svg');
            background-repeat: no-repeat;
            background-size: 9px 8px;
            z-index: 10;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__card-bottom {
        position: relative;
        display: block;
        padding-left: 35px;
        padding-right: 35px;
        margin-bottom: 20px;
    }
    &__card-inner-title {
        display: block;
        font-size: 15px;
        font-weight: 500;
        color: #4285F4;
        margin-bottom: 12px;
    }
    &__card-block .tariff-btn--select {
        margin: 0 auto;
    }
    &__card-block.medium .tariff-btn--select {
        background-color: #4285F4;
    }
    &__card-block.large .tariff-btn--select {
        background-color: #EA4335;
    }
    &__card-block.active.small {
        border: 2px solid #34A853;
    }
    &__card-block.active.medium {
        border: 2px solid #4285F4;
    }
    &__card-block.active.large {
        border: 2px solid #EA4335;
    }
    &__card-block.active .tariff-btn--select span.active {
        display: none;
    }
    &__card-block.active .tariff-btn--select span.disabled {
        display: block;
    }
    &__card-block.active .tariff-btn--select svg {
        display: block;
    }
}

.tariff-btn {
    display: block;
    text-align: center;
    border-radius: 4px;
    color: #fff;

    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;

    line-height: 1;

    padding-top: 14px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;

    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }

    &:active {
        box-shadow: none;
    }

    &:active,
    &:focus,
    &:hover {
        color: #fff;
        text-decoration: none !important;
        outline: none !important;
    }
}

.tariff-btn--select {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 40px;
    border-radius: 8px;
    background-color: #34A853;
    transition: all 0.5s ease-in-out;
    svg {
        display: none;
        margin-right: 6px;
        stroke: #34A853;
        transition: all 0.5s ease-in-out;
    }
    span.active {
        display: block;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.46;
        color: #fff;
        transition: all 0.5s ease-in-out;
    }
    span.disabled {
        display: none;
        transition: all 0.5s ease-in-out;
    }

    &_disabled {
        background-color: darkgray !important;

        svg {
            stroke: darkgray !important;
        }
    }
}

@include for-size(mobile) {
    .pricing-block {
        &__card-block {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
        &__card-block:not(:nth-child(3n)) {
            margin-right: 0;
        }
        &__control-block {
            margin-bottom: 45px;
        }
        .tariff-btn--select {
            max-width: 240px;
        }
    }
}

@include for-size(tablet) {
    .pricing-block {
        &__card-block {
            margin-bottom: 20px;
        }
        &__card-block:not(:nth-child(3n)) {
            margin-right: 0;
        }
        &__card-block:not(:nth-child(2n)) {
            margin-right: 20px;
        }
    }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
}