.loading-overlay {
  &__wrap {
    position: relative;
  }

  &__loader {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    top: 0;
    background: rgba(255, 255, 255, 0.3);
  }
}