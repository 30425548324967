@import "./custom-variables.module.scss";

.noti-icon .badge {
  left: 23px;
}
//.mt-2 {
//  margin-top: 20px !important;
//}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1003 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}


// AUTH
.auth-radio-form-control {
  background-color: transparent;
}

@media (min-width: 1200px) {
  .auth-full-bg {
    height: auto;
    min-height: 100%;
  }
}

// Avatar
.avatar-cover {
  object-fit: cover;
}

.avatar-select-wrap {
  position: relative;
}

.avatar-label {
  margin: 0;
  cursor: pointer;
}

.avatar-loading {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-brand {
  color: $brand-main-text;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: bold;
  display: inline;
  padding: 0 8px;
  letter-spacing: 0.5px;
  vertical-align: middle;
}

.auth-logo-brand {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  padding: 0 8px;
  text-transform: uppercase;
  vertical-align: middle;
}

.auth-lang-wrap {
  z-index: 1;
  top: 1.3rem;
}

.vertical-collpsed .logo-brand {
  display: none;
}

@media (max-width: 991.98px) {
  .navbar-brand-box .logo-brand {
    display: none;
  }
}

.text-decoration-underline {
  text-decoration: underline !important;
}

// SELECT
.basic-single.is-invalid .select__control {
  border-color: $brand-red;
}

// SWITCH
.custom-control-label {
  cursor: pointer;
}

.custom-switch-md {
  padding-left: 3rem;
  line-height: 1.5rem;
}

.custom-switch-filed-wrap {
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 0;
}

.custom-switch-filed-wrap.custom-switch-md {
  .custom-control-label {
    line-height: 1.5rem;
  }
}

.custom-switch-md .custom-control-label:before {
  width: 40px;
  height: 20px;
  border-radius: 30px;
  left: -3rem;
}

.custom-switch-md .custom-control-label:after {
  width: 16px;
  height: 16px;
  left: calc(-3rem + 2px);
}

.custom-switch-md .custom-control-input:checked~.custom-control-label:after {
  transform: translateX(1.25rem);
}

// TEXT INPUT
.input-prepend-custom {
  //background-color: $primary;
}

.date-input-clear {
  cursor: pointer;
  //color: hsl(0,0%,80%);
  color: hsl(0,0%,60%);
  display: flex;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  right: 24px;
  top: 8px;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    background: url("../images/custom/input-close.svg") no-repeat;
    background-size: contain;
  }
}


// MAP
.property-map-edit {
  height: 400px;
  z-index: 0;
}

.property-map-read {
  height: 400px;
  z-index: 0;
}

// DROPZONE
.dropzone {
  min-height: auto;
}

// EDIT PROPERTY IMAGE WRAP
.input-value-layout-align {
  display: flex;
  height: 100%;
  align-items: center;
}

// PROPERTY CLIENTS
.property-clients {

}

.property-unit-container {
  max-width: 300px;
}

// card title link for accordion
.text-gray {
  color: $brand-second-text !important;
}

// PROFILE PAGE - INFO SECTION
.profile-info-container {
  @media (min-width: 1200px) {
    margin-bottom: 24px;
  }
}

.profile-info-boxes-container {
  @media (min-width: 1200px) {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
};

.profile-info-boxes-row {
  @media (min-width: 1200px) {
    height: 40%;
  }
}
.profile-card-wrap {
  @media (min-width: 1200px) {
    margin-bottom: 0 !important;
  }
}
.profile-mini-card {
  @media (min-width: 1200px) {
    height: 100%;
  }
  height: 98px;
}

.profile-mini-card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// EDIT LANGUAGE IN PROFILE
.language-select-btn {
  box-shadow: none !important;
  color: #555b6d !important;
  border: 0;
  border-radius: 0;
  background-color: transparent !important;
}

// RANGE SLIDER
.price-range-wrap {
  width: 100%;
  z-index: 0;

  .noUi-connect {
    background-color: $primary;
  }
}

// TABLE LOADER
.table-wrap {
 position: relative;
}

.table-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  top: 0;
  padding-top: 32px;
  background: rgba(255, 255, 255, 0.3);
}

.table-part-bordered {
  td, th {
    border-left-width: 0;
  }

  td:last-child, th:last-child {
    border-right-width: 0;
  }
}

.table-row-separator {
  tr:first-child th, tr:first-child td,  {
    border-top: none;
  }
}

// FORM
.form-field-vertical-center {
  display: flex;
  height: 100%;
  align-items: center;
}

// PAGINATION
.pagination {
  user-select: none;
}

// SEARCH
.app-search {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.search-hint {
  margin-left: 13px;
  font-size: 12px;
  font-style: italic;
}

.form-left-accessory {
  position: absolute;
  top: 0;
  left: 0.5rem;
  z-index: 4;
  height: 100%;
  display: flex;
  align-items: center;
}


// ADD FAVORITE
.favorite-label {
  line-height: 24px;
  //color: $gray-700;
}

// DASHBOARD
.consultant-dashboard-chart-card {
  height: 320px;
}

// DATASHOWER LOADER
.data-shower-loader-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// PROPERTY LIST TABLE
.property-table-image-column-header {
  min-width: 9rem;
}

.property-image-cell {
  padding: 0 !important;
  height: 6rem;
  width: 9rem;
}

.property-cell-image {
  //height: 6rem;
  //width: 9rem;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

// SOCIAL LINKING
.social-link-name {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.social-linking-button {
  height: 94px;
}


// CHAT
.not-select-chat {
  min-height: 665px;
}

.chat-item-right-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 39px;
}

.chat-attachment-icon {
  position: relative;
  top: 2px;
}

.chat-search-box {
  min-height: 65px;
}

.chat-conversation .ps__rail-y {
  z-index: 1;
}

.chat-room-header-user-status {
  min-height: 20px;
}

.chat-leftsidebar {
  @media (min-width: 992px) {
   width: 380px;
  }
}

// MENU

// background logo
.sidebar-enable .vertical-menu:before {
  display: none;
}

.vertical-menu:before{
  content: '';
  width: 90px;
  height: 350px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: url("../images/custom/brikk-side-menu-bg.svg") no-repeat;
  background-size: contain;
  z-index: 0;
  display: none;

  @media (min-height: 850px) {
    display: block;
  }
}

#sidebar-menu ul li .badge {
  margin-top: 0;
}

.vertical-collpsed .vertical-menu #sidebar-menu .badge {
  display: block !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li {
  white-space: initial;
}


// PROPERTY WIZARD
.wizard-nav-link {
  max-width: 300px;
  height: 200px;
  width: 100%;
  margin: 1rem;
}

.wizard-nav-item {
  width: 100%;
  height: 100%;
  border: 1px solid #49505726;
  border-radius: .25rem;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0.75rem 1.5rem #12263f17;
  flex-direction: column;
  justify-content: center;
  color: #495057;
  font-size: 18px;

  i {
    font-size: 32px;
  }
}

.wizard-nav-link:not(.disabled):hover .wizard-nav-item {
  box-shadow: 0 0.75rem 1.5rem #12263f24;
}

.wizard-wrap {
  margin-top: 16px;
}

.progress-xxl {
  height: 20px;
  font-size: 0.8125rem;
}


// CUSTOM CHECKBOX
.custom-checkbox {
  z-index: 0;
}

// CUSTOM CHECKBOX
.custom-radio {
  z-index: 0;
}


// NAV PILLS (nav tabs)
.nav-pills .nav-link {
  border: 1px solid transparent;
}
.nav-pills .nav-link:not(.active) {
  border: 1px solid $gray-200;
}

.nav-pills.bg-border {
  border: 0 solid;
  border-color: $gray-200;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

// PAYMENT CARD SELECT
.basic-single {
  .payment-label {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    &__number {
      min-width: 250px;
    }

    &__date {
      min-width: 170px;
    }

    &__payment-system {

    }
  }

  .select__control {
    .payment-label {
      &__date {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &__payment-system {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}


// PROPERTY INFO SECTIONS
.card_pv_sm {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.card_pv_xs {
  padding-top: .25rem;
  padding-bottom: .25rem;
}


// COMPANY LOGO
.company-logo {
  background-size: cover;
  background-position: center;

  &_size_m {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 1.875rem;
  }
}

.text-black {
  color: $brand-main-text;
}
//
//.main-content {
//  overflow: visible !important;
//}


.trial-alert_enabled {
  #page-topbar {
    box-shadow: none;
  }

  .page-content {
    padding: calc(#{$trial-alert-height} + #{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
  }
}

.dashboard-property-overview-list-block {
  min-height: 400px;
}

.import-properties-list {
  ::marker {
    content: counter(list-item) ". ";
    color: #00b7a8;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
  }
  li {
    margin-bottom: 0.5rem;
  }
}