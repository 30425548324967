.property-grid-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.property-card {
  position: relative;
  display: grid;
  grid-template-columns: 7fr 8fr 1fr;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  box-shadow: 0 3px 22px rgba(0, 0, 0, 0.04);
  border-radius: .375rem;


  @media (max-width: 1340px) {
    grid-template-columns: 7fr 8fr;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }


  &__image-block {
    position: relative;
    overflow: hidden;
    height: 336px;
    border-radius: .375rem 0 0 .375rem;
    @media (max-width: 992px) {
      width: auto;
      border-radius: .375rem .375rem 0 0;
    }
    @media (min-width: 2000px) {
      height: 400px;
    }
  }

  &__image {
    height: 100%;
    object-position: center;
    width: 100%;
    object-fit: cover;
  }

  &__image-link {
    height: 100%;
  }

  &__label-block {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 0.75rem;
    padding: 15px;

    @media (max-width: 1340px) {
      flex-direction: row;
      position: absolute;
      bottom: 20px;
      right: 20px;
      padding: 0;
    }

    .label {
      width: 32px;
      height: 32px;
      background-color: white;
      border-radius: 50%
    }
  }



  &__info-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.25rem 1.25rem 1.25rem;

    @media (max-width: 992px) {
      gap: 1rem;
    }
  }

  &__status_mark {
    position: absolute;
    top: 20px;
    right: 20px;
  }

}