.price_block {
  display: flex;
  align-items: center;
  color: #495057;
  font-weight: 500;
}

.price_with_offer {
  align-self: flex-start;
  margin-left: 0.5rem;
  color: #9c9c9c;
}