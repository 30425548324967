/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.94rem + 2px);
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.StripeElement--focus {
    color: #495057;
    background-color: #fff;
    border-color: #b1bbc4;
    outline: 0;
    box-shadow: none;
}

.StripeElement--invalid,
.invalid {
    border-color: #f46a6a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}