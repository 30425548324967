.property-images-block {
  position: relative;
  overflow: hidden;

  &__favorite_badge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 50%
  }
}
