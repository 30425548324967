.dashboard-favorite-properties-list {
  position: relative;

  .favorite-property-card {

    &__block {
      min-width: 180px;
      //height: 248px;
    }

    &__img-block {
      width: 100%;
      height: 120px;
    }

    &__img {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 2px;
    }

    &__address-block {
      display: flex;
      flex: 1;
      align-items: center;
      height: 3.375rem;
    }

    &__address {
      color: #495057;
      display: block;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 3.375rem;
      line-height: 1.125rem;
      font-weight: 500;

      &:hover {
        color: #495057;
        text-decoration: underline !important;
      }
    }

    &__details-info-block {
      display: flex;
      height: 1.125rem;
    }

    &__details-item {
      display: flex;
      align-items: center;
      margin-right: 1.25rem;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__details-item-value {
      white-space: nowrap;
    }

    &__separator {
      height: 1px;
      background: #EFF2F7;
    }

    &__footer-block {
      display: flex;
      justify-content: space-between;
    }

    &__price-block {
    }


  }
}