@import "../../../assets/scss/custom-variables.module.scss";

.trial-alert {
    position: fixed;
    top: $header-height;
    right: 0;
    left: 0;
    z-index: 1001;
    background-color: #ffffff;
    box-shadow: $box-shadow;
    margin-left: $sidebar-width;

    @media (max-width: 992px) {
        margin-left: 0 !important;
    }

    &__block {
        display: flex;
        width: 100%;
        height: $trial-alert-height;
        -ms-flex-pack: justify;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 0 calc(#{$grid-gutter-width} / 2);
        background: #ff5400;
        color: #ffffff;
    }
}

.vertical-collpsed .trial-alert {
    margin-left: $sidebar-collapsed-width;

    @media (max-width: 992px) {
        margin-left: $sidebar-collapsed-width !important;
    }
}