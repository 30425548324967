@import "../../../assets/scss/custom-variables.module";

.label {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1;
  transition: transform 0.2s;
  cursor: pointer;

  i {
    color: $brand-placeholder;
  }

  &:hover {
    transform: scale(1.2);
  }
}