@mixin for-size($size) {
  @if $size == mobile {
    @media (max-width: 767px) { @content; }
  } @else if $size == tablet {
    @media (min-width: 768px) and (max-width: 1199px) { @content; }
  } @else if $size == desktop {
    @media (min-width: 1200px) { @content; }
  }
}

.pricing-block {
  position: relative;
  padding-top: 120px;
  background-color: #F8F8FB;
  overflow: hidden;

  &__credits-block {
    position: relative;
    display: block;
    margin-bottom: 120px;
  }
  &__credits-title {
    display: block;
    font-size: 66px;
    font-weight: 500;
    line-height: 1.1;
    color: #354052;
    margin-bottom: 50px;
  }
  &__credits-list {
    position: relative;
    display: block;
  }
  &__credits-card-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    margin: 0;
    padding-left: 35px;
    padding-right: 37px;
    border-radius: 10px;
    border: 2px solid #fff;
    background-color: #fff;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
  &__credits-card-block.active {
    border-color: #34A853;
  }
  &__credits-card-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__validate-wrapper {
    position: relative;
    display: block;
    top: -2px;
  }
  &__control-label {
    margin: 0;
  }
  &__control-check {
    position: relative;
    display: inline-block;
    width: 17px;
    height: 17px;
    padding: 7px 6px;
    margin-right: 10px;
    border: 1px solid #A6B0CF;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
  }
  &__control-check-v {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #34A853;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  &__credits-card-title {
    position: relative;
    display: block;
    width: 340px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.68;
    color: #354052;
    margin-right: 70px;
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      right: -39px;
      width: 1px;
      height: 24px;
      background-color: #EBF0FB;
    }
    span {
      font-weight: 600;
    }
  }
  &__credits-card-price {
    position: relative;
    display: block;
    width: 120px;
    font-size: 16px;
    font-weight: 400;
    color: #354052;
    margin-right: 70px;
    line-height: 1.68;
    span {
      font-weight: 600;
    }
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      right: -39px;
      width: 1px;
      height: 24px;
      background-color: #EBF0FB;
    }
  }
  &__credits-card-discount {
    display: block;
    font-weight: 400;
    font-size: 16px;
    color: #34A853;
    line-height: 1.68;
    span {
      font-weight: 600;
    }
  }
  &__credits-card-sum {
    position: relative;
    display: block;
    width: 60px;
    font-size: 20px;
    font-weight: 600;
    color: #354052;
    line-height: 1.7;
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: -30px;
      width: 1px;
      height: 24px;
      background-color: #EBF0FB;
    }
  }
}

input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
  display: none;
}

input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label {
  display: flex;
  position: relative;
  cursor: pointer;
}

input[type="radio"]:checked + label  .pricing-block__control-check {
  border: 1px solid #34A853;
}

input[type="radio"]:checked + label .pricing-block__control-check-v {
  opacity: 1;
}

@include for-size(mobile) {
  .pricing-block {
    padding-top: 60px;
    &__credits-title {
      font-size: 36px;
      margin-bottom: 25px;
    }
    &__credits-card-block {
      flex-wrap: wrap;
      padding-top: 30px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 30px;
      height: auto;
    }
    &__credits-card-wrapper {
      display: block;
      width: 100%;
    }
    &__credits-card-title {
      width: 100%;
      text-align: center;
      flex-shrink: 0;
      margin-right: 0;
      font-size: 14px;
      margin-bottom: 10px;
      &::after {
        display: none;
      }
    }
    &__credits-card-price {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      &::after {
        display: none;
      }
    }
    &__credits-card-sum {
      width: 100%;
      text-align: center;
    }
    &__validate-wrapper {
      flex-shrink: 0;
    }
    &__control-label {
      justify-content: center;
      margin-bottom: 20px;
    }
    &__control-check {
      margin-right: 0;
    }
    &__credits-card-discount {
      text-align: center;
      margin-bottom: 10px;
    }
    &__credits-block {
      margin-bottom: 60px;
    }
  }
}

@include for-size(tablet) {
  .pricing-block {
    padding-top: 90px;
    &__credits-card-block {
      padding-left: 20px;
      padding-right: 20px;
    }
    &__credits-card-title {
      margin-right: 35px;
      &::after {
        right: -16px;
      }
    }
    &__credits-card-price {
      margin-right: 35px;
      &::after {
        right: -16px;
      }
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .pricing-block {
    &__logo-block {
      right: -100px;
    }
  }
}