.property-ribbon {
  width: 200px;
  padding: 4px;
  position: absolute;
  text-align: center;
  color: #ffffff;
  background: #58BAAB;
  z-index: 1;

  &_cr_top    { top: 16px; }
  &_cr_bottom { bottom: 25px; }
  &_cr_left   { left: -64px; }
  &_cr_right  { right: -50px; }
}

/* Rotations */
.property-ribbon_cr_top.property-ribbon_cr_left,
.property-ribbon_cr_bottom.property-ribbon_cr_right {
  transform: rotate(-30deg);
}

.property-ribbon_cr_top.property-ribbon_cr_right,
.property-ribbon_cr_bottom.property-ribbon_cr_left {
  transform: rotate(30deg);
}