.property-details-block {
  table {
    th, td {
      padding: 1rem 1.5rem 1rem 0;
    }

    th {
      width: 260px;
      min-width: 260px;
    }
  }
}