@import "src/assets/scss/custom-variables.module";

.tab {
  list-style: none;
  display: inline-flex;
  gap: 0.5em;
}

.base_link {
  color: $brand-placeholder;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  text-transform: capitalize;
  cursor: pointer;

  &::after {
    position: absolute;
    bottom: -4px;
    left: 0;
    content: "";
    height: 0;
    width: 100%;

  }

  &:hover::after {
    height: 2px;
    background-color: $brand-placeholder;
  }

  &.active {
    color: $brand-green;

    &::after {
      position: absolute;
      bottom: -4px;
      left: 0;
      content: "";
      height: 2px;
      width: 100%;
      background-color: $brand-green;
    }
  }
}

.badge {
  display: flex;
  align-items: center;
}


