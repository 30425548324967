.swiper-container {
  width: 100%;
  height: 100%;
}

.property-gallery-carousel {

  &__image {
    //height: 582px;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}