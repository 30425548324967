.property_status_mark {
  padding: 8px 0;
  width: 76px;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
}