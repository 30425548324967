.property-gallery {
  &__block {
    display: flex;
    flex-direction: column;
  };

  &__no-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    border-radius: .375rem;
    overflow: hidden;
    background: #ffffff;
  }

  &__carousel {
    display: flex;
    height: 582px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    border-radius: .375rem;
    overflow: hidden;

    @media (max-width: 767px) {
      height: 300px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      height: 400px;
    }

    @media (min-width: 1200px) and (max-width: 1699px) {
      height: 400px;
    }
  }

  &__thumbs {
    width: 100%;
    height: 64px;
    margin-top: .75rem;
    border-radius: .375rem;
    overflow: hidden;
    cursor: grab;
  }
}