@import "~bootstrap/scss/functions.scss";
@import "~bootstrap/scss/variables";
@import "./variables";

$trial-alert-height: 40px;

:export {
  mainTextColor: $brand-main-text;
  mainGreen: $brand-green;
  mainBg: $brand-bg;
  mainRed: $brand-red;
  mainOrange: $brand-orange;
  headerHeight: $header-height;
  footerHeight: $footer-height;
  gridGutterWidth: $grid-gutter-width; // page padding top
  trialAlertHeight: $trial-alert-height;
  xs: map-get($grid-breakpoints, xs); // 0px
  sm: map-get($grid-breakpoints, sm); // 576px
  md: map-get($grid-breakpoints, md); // 768px
  lg: map-get($grid-breakpoints, lg); // 992px
  xl: map-get($grid-breakpoints, xl); // 1200px
  xxl: map-get($grid-breakpoints, xxl); //1400px
}