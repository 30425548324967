.carousel-navigation {
  &__button {
    position: absolute;
    top: 50%;
    width: 45px;
    height: 45px;
    margin-top: calc(0px - 24.5px);
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    opacity: .78;

    &:hover {
      opacity: .88;
    }

    &_direction_prev {
      left: 10px;
      right: auto;
    }

    &_direction_next {
      left: auto;
      right: 10px;

      i {
        transform: rotate(180deg);
      }
    }
  }

  &__button_size_s {
    width: 36px;
    height: 36px;
    margin-top: calc(0px - 18px);
  }
}