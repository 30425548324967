.property-price-info-block {
  display: flex;
  align-items: center;
  min-height: 3.75rem;
  justify-content: space-between;

  &__price {
    font-size: 1.5625rem;
    font-weight: 600;
  }
}