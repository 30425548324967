@import "src/assets/scss/custom-variables.module";

.countBadge {
  //width: 32px;
  line-height: 8px;
  padding: 10px;
  border-radius: 40%;
  text-align: center;
  color: $brand-placeholder;
  font-weight: 600;
  font-size: 12px;
  border: 2px solid $brand-placeholder;

  &.active {
    background-color: $brand-green;
    color: $white;
    border: 2px solid $brand-bg;
  }
}

