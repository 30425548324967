@import "../../../assets/scss/custom-variables.module";

.property-favorite-button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: transform 0.2s;

  &__inactive {
    i {
      color: $brand-placeholder;
    }
    &:hover {
      transform: scale(1.2);
    }
  }

  &__active {
    i {
      color: $brand-green;
    }
    &:hover {
      transform: scale(1.2);
    }
  }

 }