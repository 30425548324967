.property-gallery-thumbs {

  &__block {
    position: relative;
    height: 100%;
    border-radius: .375rem;
    overflow: hidden;
  }

  &__image {
    //height: 64px;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: .375rem;
  }

  &__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.68);
    border-radius: .375rem;
    cursor: pointer;
  }
}

.swiper-slide-thumb-active .property-gallery-thumbs__cover {
  background: transparent;
  border: 2px solid #1F3DD0;
}