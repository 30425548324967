.block {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}

.name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.company_avatar {
  height: 36px;
  width: 36px;
}