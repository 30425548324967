.property-overview-table {
  margin-bottom: 0 !important;

  td {
    border-top: none !important;
    border-bottom: 1px solid #eff2f7 !important;
    padding: 1rem .5rem !important;
  }

  td:first-child {
    padding-left: 1rem !important;
  }

  td:last-child {
    padding-right: 1rem !important;
  }

  tr:last-child td {
    border-bottom: none !important;
  }

  &__image-cell {
    height: 5rem;
    width: 6rem;
  }

  &__image {
    background-size: cover;
    background-position: center;
    border-radius: 2px;
    height: 3rem;
    width: 4.5rem;
  }

  &__address-cell {
    //max-width: 70%;
  }

  &__address {
    color: #495057;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 2rem;
    line-height: 1rem;
    font-weight: 500;

    &:hover {
      color: #495057;
      text-decoration: underline !important;
    }
  }

  &__details-item {
    display: flex;
    align-items: center;
  }

  &__details-item-value {
    white-space: nowrap;
  }

  &__price-block {

  }
}