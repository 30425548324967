.property-company-info-block {
  display: flex;
  align-items: center;
  min-height: 3.75rem;

  &__logo {

  }

  &__name {
    display: flex;
    flex: 1;
  }
}