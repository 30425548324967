.favorite-property-carousel-navigation {
  &_direction_prev {
    left: -18px;

    &.swiper-button-disabled {
      display: none;
    }
  }

  &_direction_next {
    right: -18px;

    &.swiper-button-disabled {
      display: none;
    }
  }
}