.property-page {}

.property-info-section {

  &_offset {
    padding-top: 2.25rem + 1.5rem;
  }
}

.property-title-block {
  display: flex;

  &__address {
    margin-left: 1.25rem;
    font-weight: 600;
    display: flex;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.75rem;
    line-height: 1.875rem;
    align-self: center;
    flex: 1;
  }
}

.property-actions-block {
  @media (max-width: 1199.98px) {
    margin-top: 1.5rem;
  }
}

.property-actions-block-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.property-overview {
  height: 100%;

  @media (min-width: 1200px) {
    padding-bottom: 24px;
  }

  // Sticky block on css
  //&__sticky {
  //  position: sticky !important;
  //  position: -webkit-sticky !important;
  //  top: calc(#{$header-height} + #{$grid-gutter-width}); // header height + page padding top
  //
  //  @media (max-width: 1199.98px) {
  //    position: relative;
  //    top: 0;
  //  }
  //}
}